<template>
  <div class="head">
    <div class="titles">
      <span
        v-for="(title, index) in appTitle"
        :class="{ last: index === appTitle.length - 1 }"
        @click="to(title.path)"
        >{{ title.name }}</span
      >
    </div>
    <button
      class="btn round-secondary"
      v-if="appTitle.length > 1"
      @click="back"
    >
      返回
    </button>
  </div>
</template>

<script>
/**
 * @module components/ctheader
 * @desc 传蔬农贸版 内容页的头部
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-28
 * @copyright 2021
 */
import { isEmpty } from "../modules/utils";
import router from "@/router";
import { mapGetters } from "vuex";
export default {
  name: "ctheader",
  computed: {
    ...mapGetters(["appTitle"]),
  },
  methods: {
    /**
     * 返回
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-28
     */
    back() {
      router.go(-1);
    },
    /**
     * 跳转到某个页面
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-28
     * @param  {String}   path
     */
    to(path) {
      if (!isEmpty(path)) {
        router.push(path);
      }
    },
  },
};
</script>
