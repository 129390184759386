<template>
  <div class="wrapper" ref="page">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    />
    <div class="content1">
      <div class="table-head">
        <button class="btn primary ver-ali-top" @click="newVersion">
          新建
        </button>
        <button class="btn primary ver-ali-top mgl8" @click="setOemVersion">
          oem版本管理
        </button>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th v-for="field in heads" :class="field.class">
                {{ field.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataList">
              <!-- <td class="pw10">{{row.id}}</td> -->
              <td class="pw20">{{ row.name | ellipsis }}</td>
              <td class="pw20">{{ row.describe | ellipsis }}</td>
              <td class="pw10">
                {{ row.type == 1 ? "版本权限" : "功能权限" }}
              </td>
              <td class="pw10">{{ row.create_name }}</td>
              <td class="pw15">{{ row.created_at }}</td>
              <td class="pw5">
                <span v-if="row.status === 0">禁用</span>
                <span v-if="row.status === 1">启用</span>
              </td>
              <td class="operation pw20">
                <template v-if="row.id !== 1">
                  <a @click="edit(row.id)">编辑</a>
                  <a v-if="row.status === 0" @click="operate(row.id, 1)"
                    >启用</a
                  >
                  <a v-if="row.status === 1" @click="operate(row.id, 0)"
                    >禁用</a
                  >
                </template>
                <a @click="view(row.id)">查看</a>
              </td>
            </tr>
            <tr v-if="dataList.length === 0">
              <td class="empty" :colspan="heads.length">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <pagination :start="search.start" :total="total" :page-length="search.length" :pageCallback="list"></pagination> -->
  </div>
</template>

<script>
/**
 * @file pages/index.vue
 * @desc this is index.vue
 * @version 0.1.0
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
import { mapGetters, mapActions } from "vuex";
// import router from '@/router';
import router from "../../router";
import * as URL from "../../modules/URLs";
import APIs from "../../modules/APIs";
import { apiPost } from "../../modules/utils";
// import ctheader from '@@/ctheader';
import ctheader from "../../components/ctheader";

const getters = mapGetters([]);

const actions = mapActions([]);

const FIELDS = [
  // { name: '版本号', class: 'pw10' },
  { name: "版本名称", class: "pw20" },
  { name: "描述", class: "pw20" },
  { name: "版本类型", class: "pw10" },
  { name: "创建人", class: "pw10" },
  { name: "创建时间", class: "pw15" },
  { name: "状态", class: "pw5" },
  { name: "操作", class: "pw20" },
];

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  data() {
    return {
      heads: FIELDS,
      dataList: [],
      pageInfo: {
        titles: [],
        backBtn: false,
      },
    };
  },
  filters: {
    // 超过20位显示...
    ellipsis: function(value) {
      if (!value) return "";
      if (value.length > 10) {
        return value.slice(0, 10) + "...";
      }
      return value;
    },
  },
  components: { ctheader },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;
    });
  },
  computed: {
    ...getters,
  },
  methods: {
    view(id) {
      router.push(`${URL.VIEW_VERSION}/${id}`);
    },
    edit(id) {
      router.push(`${URL.EDIT_VERSION}/${id}`);
    },
    operate(id, type) {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "version.lock",
          id: id,
          type: type,
        },
        success(res) {
          if (res.code === "0") {
            _this.getList();
          }
        },
        error(res) {},
      });
    },
    getList() {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "list.version",
          start: 0,
          length: 9999,
        },
        success(res) {
          if (res.code === "0") {
            _this.dataList = res.data;
          }
        },
        error(res) {},
      });
    },
    newVersion() {
      router.push(URL.NEW_VERSION);
    },
    setOemVersion() {
      router.push(URL.OEM_VERSION);
    },
    ...actions,
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less">
.wrapper {
  // min-height: 100vh;
  // background: #fff;
  //
  .content1 {
    min-height: 100vh !important;
    background: #fff;
    box-shadow: 0 0 10px 0 #e0e3e8;
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 18px;
    border-radius: 4px;
    padding: 24px;
  }
}
.mgl8 {
  margin-left: 8px;
}
</style>
